var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, makeObservable, observable, computed } from "mobx";
import { Request } from "kaiju.ui2";
class ChatListStore {
    constructor(_ref) {
        let { changeCurrentCallback, loadChatsCallback } = _ref;
        this.data = [];
        this.current = undefined;
        this.edited = undefined;
        this.changeCurrentCallback = void 0;
        this.loadChatsCallback = void 0;
        this.loadChats = switchTo => {
            Request("ClientsChats.chat_list", {}).then(response => {
                if (response.result) {
                    this.data = response.result;
                    this.loadChatsCallback(response.result);
                    let chatId;
                    if (switchTo) {
                        chatId = switchTo;
                    }
                    else {
                        chatId = localStorage.getItem("current-chat-id");
                    }
                    if (chatId) {
                        const _current = this.data.find(x => x.id === chatId);
                        if (_current) {
                            this.setCurrent(_current.id); // this.current = _current.id
                        }
                    }
                    if (!this.current) {
                        this.setCurrent(this.data[0].id); // this.current = this.data[0].id
                    }
                }
            });
        };
        this.addChat = (suffix, template_id) => __awaiter(this, void 0, void 0, function* () { yield Request("ClientsChats.create_chat", { title: "Новый чат " + suffix, template: template_id }).then(response => { if (response.result) {
            this.loadChats(response.result.id);
        } }); });
        this.setTitle = (id, title) => __awaiter(this, void 0, void 0, function* () { yield Request("ClientsChats.update_title", { title: title, chat_id: id }).then(response => { if (response.result) {
            this.data.map(chat => { if (chat.id === id) {
                chat.title = title;
            } });
        } }); this.edited = undefined; });
        this.deleteChat = (chatId) => __awaiter(this, void 0, void 0, function* () { yield Request("ClientsChats.chat_delete", { chat_id: chatId }).then(response => { if (response.result) {
            localStorage.removeItem("current-chat-id");
            this.data = this.data.filter(el => el.id !== chatId);
            if (this.data.length > 0) {
                this.changeChat(this.data[0].id);
            }
            else {
                this.current = undefined;
                this.loadChats();
            }
        } }); });
        this.changeCurrentCallback = changeCurrentCallback;
        this.loadChatsCallback = loadChatsCallback;
        makeObservable(this, { data: observable, current: observable, edited: observable, loadChats: action.bound, changeChat: action.bound, addChat: action.bound, setEdit: action.bound, closeEdit: action.bound, setTitle: action.bound, deleteChat: action.bound, currentTitle: computed });
        this.loadChats();
    }
    setCurrent(chatId) { this.current = chatId; localStorage.setItem("current-chat-id", this.current); this.changeCurrentCallback(chatId); }
    changeChat(id) { if (id !== this.current) {
        this.edited = undefined;
    } this.setCurrent(id); }
    setEdit(id) { this.edited = id; }
    get currentTitle() { const c = this.data.filter(e => { return e.id === this.current; }); return c.length > 0 ? c[0].title : ""; }
    closeEdit() { this.edited = undefined; }
}
export { ChatListStore };

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx"; // export const ExportToExcel: React.FC<{ apiData: any, fileName: any }> = ({apiData, fileName}) => {
//     const fileType =
//         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//     const fileExtension = ".xlsx";
//
//     const exportToCSV = (apiData: any, fileName: any) => {
//         const ws = XLSX.utils.json_to_sheet(apiData, {skipHeader: true, header: ["1", "test"]});
//         const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
//         const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
//         const data = new Blob([excelBuffer], {type: fileType});
//         FileSaver.saveAs(data, fileName + fileExtension);
//     };
//
//     return (
//         <button onClick={(e) => exportToCSV(apiData, fileName)}>Export</button>
//     );
// };
export default _ref => { let { apiData, fileName, header } = _ref; const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"; const fileExtension = ".xlsx"; const ws = XLSX.utils.json_to_sheet(apiData, { skipHeader: true, header: header }); const wb = { Sheets: { data: ws }, SheetNames: ["data"] }; const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" }); const data = new Blob([excelBuffer], { type: fileType }); FileSaver.saveAs(data, fileName + fileExtension); };

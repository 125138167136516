var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LoadTranslations } from "kaiju.ui2";
import { redirect } from "react-router-dom";
import Request from 'src/utils/Request';
import user from "src/components/User";
const signUpLoader = (props) => __awaiter(void 0, void 0, void 0, function* () { const url = new URL(props.request.url); const redirectTo = url.searchParams.get("redirectTo") || '/'; user.UTM.saveUTMTagsCookie(); const response = yield Request('auth.user_info').then(data => { if (data.result) {
    if (data.result.permissions.includes("no_verf")) {
        return redirect('/verify');
    }
    return redirect(redirectTo);
} }); if (response) {
    return response;
} yield LoadTranslations(); return {}; });
export default signUpLoader;

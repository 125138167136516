import { getCookie, setCookie } from "typescript-cookie";
class UtmTags {
    constructor() {
        this.cookieName = 'utm_tags';
        this.domain = ".elementogpt.ru"; // domain: string = ".localhost" // for tests
        this.tagsElementsToHold = 10;
        this.allTags = [];
        this.hasNew = false;
        this._no = objectName => { return Object.keys(objectName).length === 0; };
        this.allTags = this.getUTMTags();
    }
    parseUTM() { const searchParams = new URL(window.location.href).searchParams || []; const newUTMTags = {}; searchParams.forEach(function (val, key) { if (key.startsWith("utm_"))
        newUTMTags[key] = val; }); return newUTMTags; }
    saveUTMTagsCookie() { let newTags = this.parseUTM(); if (this._no(newTags)) {
        return;
    } let lastTags = this.allTags && this.allTags.slice(-1)[0]; let lastAndNewTagsAreTheSame = typeof lastTags === 'object' && this._utmDictKey(lastTags) === this._utmDictKey(newTags); if (lastAndNewTagsAreTheSame) {
        return;
    } this.allTags.push(newTags); let cookieIsOverloaded = this.allTags.length === this.tagsElementsToHold; if (cookieIsOverloaded) {
        this.allTags.shift();
    } this.hasNew = true; setCookie(this.cookieName, JSON.stringify(this.allTags), { 'expires': 120, 'domain': this.domain }); }
    _utmDictKey(dict) { return Object.values(dict).sort().reduce((accumulator, currentValue) => accumulator + currentValue, ''); }
    getUTMTags() { try {
        return JSON.parse(getCookie("utm_tags") || "[]");
    }
    catch (e) {
        console.error("getUTMTags error", e);
        return [];
    } }
}
export default UtmTags;
